import { observer } from 'mobx-react';
import type Model from './userBanModel';
import { Button, Popconfirm } from 'antd';
import React from 'react';

export const UserBan = observer(({ store, row }: { store: Model; row: any }) => {
  const { onUserBan } = store;
  let text = '封禁';
  if (row.bizAccountStatus === 1) {
    text = '封禁';
  }
  if (row.bizAccountStatus === 0) {
    text = '解封';
  }
  return (
    <Popconfirm
      title="提示"
      description={`是否${text}该用户?`}
      onConfirm={() => onUserBan(row)}
      onOpenChange={() => console.log('open change')}
    >
      <Button type="link">{text}</Button>
    </Popconfirm>
  );
});
