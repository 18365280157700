import type { DescriptionsProps } from 'antd';
import { action, observable } from 'mobx';

export default class UserDetailModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public visible: boolean;

  @observable public parent: any;

  @observable public items: DescriptionsProps['items'] = [];

  @action public onShow = (row: any) => {
    if (row) {
      console.log(row);
      this.items = [
        {
          key: 'nickName',
          label: '用户名',
          children: row.nickName,
        },
        {
          key: 'id',
          label: '用户ID',
          children: row.id,
        },
        {
          key: 'groupCount',
          label: '进群数量',
          children: row.groupCount,
        },
        {
          key: 'orderAmount',
          label: '付费金额',
          children: row.orderAmount,
        },
        {
          key: 'mobile',
          label: '手机号',
          children: row.mobile,
        },
      ];
      this.visible = true;
    }
  };

  @action public onCancel = () => {
    this.visible = false;
  };
}
