import { action } from 'mobx';
import React from 'react';
import type { BaseData } from '../../../utils';
import { request } from '../../../utils';
import { reject } from 'underscore';
import { API } from '../api';
import { message } from 'antd';

export default class UserBanModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  private parent: any;

  @action
  public onUserBan = (row: any) =>
    request<BaseData<any>>({
      url: API.freezeUser,
      method: 'POST',
      data: { id: row.id },
    })
      .then((res) => {
        this.parent?.pageStore.grid.onQuery();
        message.success('操作成功！');
        return true;
      })
      .catch((err) => Promise.reject(err));
}
