import { observer } from 'mobx-react';
import type Model from './userDetailModel';
import React from 'react';
import { Descriptions, Modal } from 'antd';

export const UserDetail = observer(({ store }: { store: Model }) => {
  const { visible, items, onCancel } = store;
  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      onCancel={onCancel}
      open={visible}
    >
      <Descriptions
        title="用户详情"
        items={items}
      />
    </Modal>
  );
});
